import { Alert, CircularProgress, Snackbar, Step, StepLabel, Stepper } from '@mui/material';
import { createTheme, Theme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getApplication, IPlatform } from '../../../services/getApplicationService';
import Header from './Header/Header';
import ErrorMsg from '../../shared/ErrorMsg/ErrorMsg';
import ProfileStep, { IProfileState } from './Steps/ProfileStep/ProfileStep';
import { StepKey } from '../../../helpers/applicationHelper';
import BusinessTypeStep, { IBusinessTypeState } from './Steps/BusinessTypeStep/BusinessTypeStep';
import BusinessProfileStep, { IBusinessProfileState } from './Steps/BusinessProfileStep/BusinessProfileStep';
import RepresentativesStep, { IRepresentativesState } from './Steps/RepresentativesStep/RepresentativesStep';
import PayoutAccountStep, { IPayoutAccountState } from './Steps/PayoutAccountStep/PayoutAccountStep';
import { EditStatus } from "./Common/EditStatus";
import SummaryStep, { ISummaryState } from './Steps/SummaryStep/SummaryStep';
import { IBusinessTypes, getBusinessTypes } from '../../../services/getBusinessTypesService';
import { getMerchantCategories } from '../../../services/getMerchantCategoriesService';
import { IItem } from '../../shared/SelectField/SelectField';
import ApplicantDetailsStep, { IApplicantDetailsState } from './Steps/ApplicantDetailsStep/ApplicantDetailsStep';
import moment from 'moment';
import { getCountries } from '../../../services/getCountriesService';
import { getDistrictCourts } from '../../../services/getDistrictCourtsService';
import { FormStepKey, IBusinessProfileForm, getForm } from '../../../services/getFormService';
import { usePrevious } from "../../../helpers/hooks";
import { LinkRelation, DocumentType, Role, ISubmitApplicationResult, IWorkflow } from '../../../services/commonTypes';
import './OnBoarding.scss';

const OnBoarding = () => {
    const params = useParams();
    const resourceToken = params.resourceToken;
    const { t, i18n } = useTranslation();

    const initialStepsState: IStep[] = [
        { key: StepKey.Profile, nameKey: 'steps.profile.name' },
        { key: StepKey.ApplicantDetails, nameKey: 'steps.applicant_details.name' },
        { key: StepKey.BusinessType, nameKey: 'steps.business_type.name' },
        { key: StepKey.BusinessProfile, nameKey: 'steps.business_profile.name' },
        { key: StepKey.Representatives, nameKey: 'steps.representatives.name' },
        { key: StepKey.Payouts, nameKey: 'steps.payouts.name' },
        { key: StepKey.Summary, nameKey: 'steps.summary.name' }
    ];

    const initialState: IState = {
        isLoading: true,
        theme: createTheme(),
        steps: initialStepsState,
        activeStepKey: StepKey.Profile,
        showAlert: false,
        isCompleted: false
    };

    const [state, setState] = useState<IState>(initialState);
    const previousCountryCode = usePrevious(state.application?.businessTypeState?.fields.countryCode);
    const previousBusinessType = usePrevious(state.application?.businessTypeState?.fields.type);
    const previousBusinesStructure = usePrevious(state.application?.businessTypeState?.fields.structure);

    const isPreviousToActiveStep = (step: StepKey, activeStep: StepKey) => {
        if (state.isCompleted) {
            return true;
        } else {
            switch (activeStep) {
                case StepKey.Profile:
                    return false;
                case StepKey.ApplicantDetails:
                    return step === StepKey.Profile;
                case StepKey.BusinessType:
                    return step === StepKey.Profile || step === StepKey.ApplicantDetails;
                case StepKey.BusinessProfile:
                    return step === StepKey.Profile || step === StepKey.ApplicantDetails || step === StepKey.BusinessType;
                case StepKey.Representatives:
                    return step === StepKey.Profile || step === StepKey.ApplicantDetails || step === StepKey.BusinessType || step === StepKey.BusinessProfile;
                case StepKey.Payouts:
                    return step === StepKey.Profile || step === StepKey.ApplicantDetails || step === StepKey.BusinessType || step === StepKey.BusinessProfile || step === StepKey.Representatives;
                case StepKey.Summary:
                    return step === StepKey.Profile || step === StepKey.ApplicantDetails || step === StepKey.BusinessType || step === StepKey.BusinessProfile || step === StepKey.Representatives || step === StepKey.Payouts;
            }
        }
    };

    const handleProfileStateChanged = (state: IProfileState) => {
        setState(prev => ({
            ...prev,
            application: {
                ...prev.application!,
                profileState: state
            }
        }));
    };

    const handleApplicantDetailsStateChanged = (state: IApplicantDetailsState) => {
        setState(prev => ({
            ...prev,
            application: {
                ...prev.application!,
                applicantDetailsState: state
            }
        }));
    };

    const handleBusinessTypeStateChanged = (newState: IBusinessTypeState) => {
        setState(prev => ({
            ...prev,
            application: {
                ...prev.application!,
                businessTypeState: newState
            }
        }));
    };

    const handleBusinessProfileStateChanged = (newState: IBusinessProfileState) => {
        setState(prev => ({
            ...prev,
            application: {
                ...prev.application!,
                businessProfileState: newState
            }
        }));
    };

    const handleRepresentativesStateChanged = (newState: IRepresentativesState) => {
        setState(prev => ({
            ...prev,
            application: {
                ...prev.application!,
                representativesState: newState
            }
        }));
    };

    const handleBusinessPayoutAccountChanged = (newState: IPayoutAccountState) => {
        setState(prev => ({
            ...prev,
            application: {
                ...prev.application!,
                payoutAccountState: newState
            }
        }));
    }

    const handleSummaryChanged = (newState: ISummaryState) => {
        setState(prev => ({
            ...prev,
            application: {
                ...prev.application!,
                summaryState: newState
            }
        }));
    }

    const mapLinkRelationToStepKey = (linkRelation: LinkRelation): StepKey => {
        switch (linkRelation) {
            case LinkRelation.Applicant:
                return StepKey.Profile;
            case LinkRelation.ApplicantDetails:
                return StepKey.ApplicantDetails;
            case LinkRelation.BusinessType:
                return StepKey.BusinessType;
            case LinkRelation.BusinessProfile:
                return StepKey.BusinessProfile;
            case LinkRelation.Representatives:
                return StepKey.Representatives;
            case LinkRelation.PayoutAccount:
                return StepKey.Payouts;
            case LinkRelation.Submit:
                return StepKey.Summary;
            default:
                throw new Error('Not supported');
        }
    }

    const navigateToStep = (step: StepKey) => {
        setState(prev => ({ ...prev, activeStepKey: step }));
    };

    const navigateToNextStep = (result: ISubmitApplicationResult) => {
        let nextStepKey = mapLinkRelationToStepKey(result.workflow.next);

        if (result) {
            setState(prev => ({
                ...prev,
                activeStepKey: nextStepKey,
                application: {
                    ...prev.application!,
                    profileState: {
                        ...prev.application!.profileState!,
                        operationUrl: result.links[LinkRelation.Applicant].href
                    },
                    applicantDetailsState: {
                        ...prev.application!.applicantDetailsState!,
                        operationUrl: result.links[LinkRelation.ApplicantDetails].href,
                        uploadDocumentOperationUrl: result.links[LinkRelation.ApplicantDocuments].href
                    },
                    businessTypeState: {
                        ...prev.application!.businessTypeState!,
                        operationUrl: result.links[LinkRelation.BusinessType]?.href
                    },
                    businessProfileState: {
                        ...prev.application!.businessProfileState!,
                        countryCode: prev.application!.businessTypeState?.fields.countryCode || '',
                        operationUrl: result.links[LinkRelation.BusinessProfile]?.href,
                        uploadDocumentOperationUrl: result.links[LinkRelation.Documents]?.href
                    },
                    representativesState: {
                        ...prev.application!.representativesState!,
                        addRepresentativeUrl: result.links[LinkRelation.Representatives]?.href
                    },
                    payoutAccountState: {
                        ...prev.application!.payoutAccountState!,
                        operationUrl: result.links[LinkRelation.PayoutAccount]?.href,
                        uploadDocumentOperationUrl: result.links[LinkRelation.Documents]?.href
                    },
                    summaryState: {
                        ...prev.application!.summaryState!,
                        operationUrl: result.links[LinkRelation.Submit]?.href
                    }
                }
            }));
        }
    };

    const isActiveStep = (step: IStep) => {
        return step.key === state.activeStepKey;
    };

    const handleAlertClose = () => {
        setState(prev => ({ ...prev, showAlert: false }));
    };

    const handleError = (message?: string) => {
        const alertMessage = message || t('errors.generic_unexpected_error');

        setState(prev => ({ ...prev, showAlert: true, alertMessage: alertMessage }));
    };

    const handleBack = () => {
        let previousStepKey: StepKey;

        switch (state.activeStepKey) {
            case StepKey.ApplicantDetails:
                previousStepKey = StepKey.Profile;
                break;
            case StepKey.BusinessType:
                previousStepKey = StepKey.ApplicantDetails;
                break;
            case StepKey.BusinessProfile:
                previousStepKey = StepKey.BusinessType;
                break;
            case StepKey.Representatives:
                previousStepKey = StepKey.BusinessProfile;
                break;
            case StepKey.Payouts:
                previousStepKey = StepKey.Representatives;
                break;
            case StepKey.Summary:
                previousStepKey = StepKey.Payouts;
                break;
            default:
                throw new Error('Not supported');
        }

        setState(prev => ({ ...prev, activeStepKey: previousStepKey }));
    };

    const handleCompleted = () => {
        setState(prev => ({ ...prev, isCompleted: true }));
    }

    const mapWorkflowToStepKey = (workflow: IWorkflow): StepKey => {
        if (!workflow.complete && workflow.next) {
            switch (workflow.next) {
                case LinkRelation.Applicant:
                    return StepKey.Profile;
                case LinkRelation.ApplicantDetails:
                    return StepKey.ApplicantDetails;
                case LinkRelation.BusinessType:
                    return StepKey.BusinessType;
                case LinkRelation.BusinessProfile:
                    return StepKey.BusinessProfile;
                case LinkRelation.Representatives:
                case LinkRelation.ConfirmRepresentatives:
                    return StepKey.Representatives;
                case LinkRelation.PayoutAccount:
                    return StepKey.Payouts;
                case LinkRelation.Submit:
                    return StepKey.Summary;
                default:
                    throw new Error('Not supported');
            }
        } else if (workflow.complete && !workflow.next) {
            return StepKey.Summary;
        } else {
            throw new Error('Not supported');
        }
    };

    useEffect(() => {
        if (!resourceToken) {
            return;
        }

        (async () => {
            try {
                const application = await getApplication(resourceToken!);

                const redirectLink = application.links[LinkRelation.Redirect];
                if (redirectLink) {
                    window.location.href = redirectLink.href;
                }

                const merchantCategories = await getMerchantCategories(resourceToken!);
                const merchantCategoryItems = merchantCategories.map<IItem>(category => {
                    return { value: category.code, description: category.description }
                });

                const countries = await getCountries(resourceToken!, application.language);

                let districtCourtItems: IItem[] = [];
                if (application.business?.profile?.districtCourtCode) {
                    const districtCourts = await getDistrictCourts(resourceToken, application.business.countryCode);
                    districtCourtItems = districtCourts.map<IItem>(districtCourt => {
                        return { value: districtCourt.code, description: districtCourt.name };
                    });
                }

                let businessTypes: IBusinessTypes;
                let typeValue: string | undefined;
                let types: IItem[] = [];
                let structures: IItem[] | undefined = [];
                let structureValue: string | undefined;
                let businessProfileForm: IBusinessProfileForm | undefined;

                if (application.business?.countryCode) {
                    businessTypes = await getBusinessTypes(resourceToken!, application.business.countryCode);
                    typeValue = application.business?.type || businessTypes.defaultValue;
                    types = businessTypes.items.map<IItem>(t => { return { description: t.label, value: t.value } });
                    const selectedBusinessTypeItem = businessTypes.items.find(t => t.value === typeValue);
                    structures = selectedBusinessTypeItem!.children?.items.map<IItem>(s => { return { description: s.label, value: s.value } });
                    structureValue = application.business?.structure || selectedBusinessTypeItem!.children?.defaultValue;
                    businessProfileForm = await getForm(resourceToken!, FormStepKey.BusinessProfile);
                }

                i18n.changeLanguage(application.language);
                const activeStepKey = mapWorkflowToStepKey(application.workflow);

                setState(prev => ({
                    ...prev,
                    application: {
                        ...prev.application,
                        platform: application.platform,
                        profileState: {
                            isLoading: false,
                            operationUrl: application.links[LinkRelation.Applicant]?.href,
                            greetingsFirstName: application.applicant?.firstName,
                            fields: {
                                firstName: application.applicant?.firstName,
                                lastName: application.applicant?.lastName,
                                email: application.applicant?.email,
                                authorized: isPreviousToActiveStep(StepKey.Profile, activeStepKey)
                            },
                            errors: {}
                        },
                        applicantDetailsState: {
                            isLoading: false,
                            countries: countries,
                            operationUrl: application.links[LinkRelation.ApplicantDetails]?.href,
                            isTipsDialogVisible: false,
                            isDataProtectionPolicyVisible: false,
                            isTermsAndConditionsVisible: false,
                            uploadDocumentOperationUrl: application.links[LinkRelation.ApplicantDocuments]?.href,
                            fields: {
                                nationality: application.applicant?.nationality,
                                gender: application.applicant?.gender,
                                dateOfBirth: application.applicant?.dateOfBirth ? moment(application.applicant.dateOfBirth) : null,
                                isDirector: (application.applicant?.roles && application.applicant.roles.some(r => r === Role.Director)) ?? false,
                                isOwner: (application.applicant?.roles && application.applicant.roles.some(r => r === Role.Owner)) ?? false,
                                pep: application.applicant?.pep || false,
                                pepDescription: application.applicant?.pepDescription,
                                dataProtectionPolicyAccepted: isPreviousToActiveStep(StepKey.ApplicantDetails, activeStepKey),
                                termsAndConditionsAccepted: isPreviousToActiveStep(StepKey.ApplicantDetails, activeStepKey)
                            },
                            errors: {},
                            uploadedDocuments: application.applicant?.documents || [],
                        },
                        businessTypeState: {
                            isLoading: false,
                            operationUrl: application.links[LinkRelation.BusinessType]?.href,
                            fields: {
                                countryCode: application.business?.countryCode,
                                type: typeValue,
                                structure: structureValue
                            },
                            businessTypes: businessTypes,
                            types: types,
                            structures: structures ?? [],
                            countries: countries,
                            errors: {}
                        },
                        businessProfileState: {
                            isLoading: false,
                            countries: countries,
                            districtCourts: districtCourtItems,
                            operationUrl: application.links[LinkRelation.BusinessProfile]?.href,
                            uploadDocumentOperationUrl: application.links[LinkRelation.Documents]?.href,
                            countryCode: application.business?.countryCode || '',
                            uploadedDocuments: application.documents?.filter(d => d.type === DocumentType.CompanyRegistration) || [],
                            fields: {
                                legalName: application.business?.profile?.legalName,
                                tradingName: application.business?.profile?.tradingName,
                                registrationNumber: application.business?.profile?.registrationNumber,
                                districtCourtCode: application.business?.profile?.districtCourtCode,
                                vatNumber: application.business?.profile?.vatNumber,
                                website: application.business?.profile?.website,
                                mcc: application.business?.profile?.mcc,
                                line1: application.business?.profile?.registeredAddress?.line1,
                                line2: application.business?.profile?.registeredAddress?.line2,
                                houseNumber: application?.business?.profile?.registeredAddress?.number,
                                townCity: application.business?.profile?.registeredAddress?.townCity,
                                state: application.business?.profile?.registeredAddress?.state,
                                zip: application.business?.profile?.registeredAddress?.zip,
                                countryPrefix: application.business?.profile?.phoneNumber?.countryCode,
                                phoneNumber: application.business?.profile?.phoneNumber?.number || ''
                            },
                            errors: {},
                            merchantCategories: merchantCategoryItems,
                            form: businessProfileForm
                        },
                        representativesState: {
                            isLoading: false,
                            isLoadingRepresentatives: false,
                            countries: countries,
                            addRepresentativeUrl: application.links[LinkRelation.Representatives]?.href,
                            confirmRepresentativesUrl: application.links[LinkRelation.ConfirmRepresentatives]?.href,
                            addedRepresentatives: application.business?.representatives
                                ? application.business?.representatives?.map(r => {
                                    return {
                                        id: r.id,
                                        firstName: r.firstName,
                                        lastName: r.lastName,
                                        email: r.email,
                                        nationality: r.nationality,
                                        dateOfBirth: moment(r.dateOfBirth).local(),
                                        pep: r.pep,
                                        pepDescription: r.pepDescription,
                                        roles: r.roles
                                    };
                                })
                                : [],
                            fields: { dateOfBirth: null, pep: false, isDirector: false, isOwner: false },
                            errors: {},
                            isAddingRepresentative: false,
                            linkPastedShowAlert: false,
                            userConfirmation: isPreviousToActiveStep(StepKey.Representatives, activeStepKey)
                        },
                        payoutAccountState: {
                            isLoading: false,
                            countries: countries,
                            uploadedDocuments: application.documents?.filter(d => d.type === DocumentType.BankAccountProof) || [],
                            uploadDocumentOperationUrl: application.links[LinkRelation.Documents]?.href,
                            fields: {
                                accountOwnersName: application.payoutAccount?.accountOwnersName || '',
                                iban: application.payoutAccount?.accountNumber.value || ''
                            },
                            editStatus: application.payoutAccount?.accountNumber.value === undefined ? EditStatus.New : EditStatus.EditDisabled,
                            errors: {},
                            operationUrl: application.links[LinkRelation.PayoutAccount]?.href,
                        },
                        summaryState: {
                            isLoading: false,
                            operationUrl: application.links[LinkRelation.Submit]?.href,
                            alreadySubmitted: application.workflow.complete,
                        }
                    },
                    activeStepKey: activeStepKey,
                    theme: createTheme({
                        palette: {
                            primary: {
                                main: application.platform.colors.primaryColor
                            },
                            secondary: {
                                main: application.platform.colors.secondaryColor
                            }
                        }
                    }),
                    isLoading: false,
                    isCompleted: application.workflow.complete,
                    isPageLoaded: true
                }));
            } catch (error) {
                console.log(error);
                setState(prev => ({ ...prev, isLoading: false }));
                handleError();
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // If it's the first load, we don't want to clean the dynamic fields (it might override data that is already submitted)
        if (previousCountryCode === undefined ||
            previousBusinessType === undefined ||
            previousBusinesStructure === undefined) {
            return;
        }

        setState(prev => ({
            ...prev,
            application: {
                ...prev.application!,
                businessProfileState: {
                    ...prev.application!.businessProfileState!,
                    fields: {
                        ...prev.application!.businessProfileState!.fields,
                        registrationNumber: undefined,
                        vatNumber: undefined,
                        districtCourtCode: undefined
                    },
                    errors: {
                        ...prev.application!.businessProfileState!.errors,
                        registrationNumber: undefined,
                        vatNumber: undefined,
                        districtCourtCode: undefined
                    }
                }
            }
        }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        state.application?.businessTypeState?.fields.countryCode,
        state.application?.businessTypeState?.fields.structure,
        state.application?.businessTypeState?.fields.type
    ]);

    return (
        <div className='onboarding-page-container'>
            {state.isLoading &&
                <div className='onboarding-page-container-loading'>
                    <CircularProgress size={70} />
                </div>
            }

            {!state.isLoading && state.application &&
                <ThemeProvider theme={state.theme}>
                    <div className='onboarding-page-container-loaded'>
                        <Header logoUrl={state.application.platform.logoUrl} primaryColor={state.application.platform.colors.primaryColor} />
                        <Container maxWidth="md" className='wizard'>
                            <Stepper activeStep={0} alternativeLabel className='wizard-stepper'>
                                {state.steps.map((step) => (
                                    <Step key={step.key} completed={isPreviousToActiveStep(step.key, state.activeStepKey)} active={isActiveStep(step)}>
                                        <StepLabel>{t(step.nameKey)}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>

                            {state.activeStepKey === StepKey.Profile &&
                                <ProfileStep
                                    resourceToken={resourceToken!}
                                    platformName={state.application.platform.name}
                                    state={state.application.profileState!}
                                    onCompleted={navigateToNextStep}
                                    onError={handleError}
                                    onStateChanged={handleProfileStateChanged} />
                            }

                            {state.activeStepKey === StepKey.ApplicantDetails &&
                                <ApplicantDetailsStep
                                    resourceToken={resourceToken!}
                                    state={state.application.applicantDetailsState!}
                                    onCompleted={navigateToNextStep}
                                    onError={handleError}
                                    onBack={handleBack}
                                    onStateChanged={handleApplicantDetailsStateChanged} />
                            }

                            {state.activeStepKey === StepKey.BusinessType &&
                                <BusinessTypeStep
                                    resourceToken={resourceToken!}
                                    state={state.application.businessTypeState!}
                                    onCompleted={navigateToNextStep}
                                    onError={handleError}
                                    onBack={handleBack}
                                    onStateChanged={handleBusinessTypeStateChanged} />
                            }

                            {state.activeStepKey === StepKey.BusinessProfile &&
                                <BusinessProfileStep
                                    resourceToken={resourceToken!}
                                    state={state.application.businessProfileState!}
                                    onCompleted={navigateToNextStep}
                                    onError={handleError}
                                    onBack={handleBack}
                                    onStateChanged={handleBusinessProfileStateChanged} />
                            }

                            {state.activeStepKey === StepKey.Representatives &&
                                <RepresentativesStep
                                    resourceToken={resourceToken!}
                                    state={state.application.representativesState!}
                                    onCompleted={navigateToNextStep}
                                    onError={handleError}
                                    onBack={handleBack}
                                    onStateChanged={handleRepresentativesStateChanged} />
                            }

                            {state.activeStepKey === StepKey.Payouts &&
                                <PayoutAccountStep
                                    resourceToken={resourceToken!}
                                    platformName={state.application.platform.name}
                                    state={state.application.payoutAccountState!}
                                    accountType="business"
                                    onCompleted={navigateToNextStep}
                                    onError={handleError}
                                    onBack={handleBack}
                                    onSkipped={() => setState(prev => ({ ...prev, activeStepKey: StepKey.Summary }))}
                                    onStateChanged={handleBusinessPayoutAccountChanged} />
                            }

                            {state.activeStepKey === StepKey.Summary &&
                                <SummaryStep
                                    applicationState={state.application!}
                                    resourceToken={resourceToken!}
                                    onError={handleError}
                                    onBack={handleBack}
                                    onNavigate={navigateToStep}
                                    onCompleted={handleCompleted}
                                    onStateChanged={handleSummaryChanged} />
                            }
                        </Container>

                        <Snackbar open={state.showAlert} autoHideDuration={6000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                            <Alert onClose={handleAlertClose} severity="error" sx={{ width: '100%' }}>
                                {state.alertMessage}
                            </Alert>
                        </Snackbar>
                    </div>
                </ThemeProvider>
            }

            {!state.isLoading && !state.application &&
                <ErrorMsg text={t('errors.initial_loading_unexpected_error')} />
            }
        </div >
    );
};

export default OnBoarding;

interface IState {
    isLoading: boolean;
    application?: IApplication;
    theme: Theme;
    steps: IStep[];
    activeStepKey: StepKey;
    isCompleted: boolean;
    showAlert: boolean;
    alertMessage?: string;
}

export interface IApplication {
    platform: IPlatform;
    profileState?: IProfileState,
    applicantDetailsState?: IApplicantDetailsState,
    businessTypeState?: IBusinessTypeState,
    businessProfileState?: IBusinessProfileState,
    representativesState?: IRepresentativesState,
    payoutAccountState?: IPayoutAccountState,
    summaryState?: ISummaryState
}

interface IStep {
    key: StepKey;
    nameKey: string;
}
